import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IconDefinition } from '@ant-design/icons-angular';
import {
  AppstoreOutline,
  ArrowLeftOutline,
  BankOutline,
  DeleteOutline,
  EditOutline,
  EyeInvisibleOutline,
  EyeOutline,
  FacebookFill,
  GlobalOutline,
  GoogleOutline,
  HomeOutline,
  InboxOutline,
  LinkOutline,
  LockOutline,
  LogoutOutline,
  MailOutline,
  PictureTwoTone,
  PlusOutline,
  TeamOutline,
  UnorderedListOutline,
  UserAddOutline,
  UserOutline,
  VideoCameraOutline
} from '@ant-design/icons-angular/icons';
import { ImagekitioAngularModule } from 'imagekitio-angular';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTransferModule } from 'ng-zorro-antd/transfer';
import { NzUploadModule } from 'ng-zorro-antd/upload';

import { SpinnerComponent } from './components/spinner/spinner.component';
import { ImageModalComponent } from './components/image-modal/image-modal.component';

const icons: IconDefinition[] = [
  AppstoreOutline,
  ArrowLeftOutline,
  BankOutline,
  DeleteOutline,
  EditOutline,
  EyeInvisibleOutline,
  EyeOutline,
  FacebookFill,
  GlobalOutline,
  GoogleOutline,
  HomeOutline,
  InboxOutline,
  LinkOutline,
  LogoutOutline,
  LockOutline,
  MailOutline,
  PictureTwoTone,
  PlusOutline,
  TeamOutline,
  UnorderedListOutline,
  UserAddOutline,
  UserOutline,
  VideoCameraOutline
];

@NgModule({
  declarations: [SpinnerComponent, ImageModalComponent],
  imports: [CommonModule, NzIconModule.forRoot(icons), NzSpinModule],
  exports: [
    NzCollapseModule,
    DragDropModule,
    FormsModule,
    NzButtonModule,
    NzCardModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzDividerModule,
    NzDropDownModule,
    NzFormModule,
    NzGridModule,
    NzIconModule,
    NzInputModule,
    NzInputNumberModule,
    NzImageModule,
    NzLayoutModule,
    NzMenuModule,
    NzModalModule,
    NzPageHeaderModule,
    NzRadioModule,
    NzSelectModule,
    NzSkeletonModule,
    NzTableModule,
    NzTabsModule,
    NzTagModule,
    NzToolTipModule,
    NzTransferModule,
    NzUploadModule,
    NzSwitchModule,
    SpinnerComponent,
    ReactiveFormsModule,
    ImagekitioAngularModule
  ]
})
export class SharedModule {}
