import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FanExperienceViewModel, OrganizationViewModel } from '../models/organization.model';
import { User, UserViewModel } from '../models/user.model';
import { SubscriberList } from '@features/models/subscriberList.model';
import { Result } from 'src/app/shared/models/result.model';
import { ORGANIZATION } from 'src/app/shared/utility/global.constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  constructor(private http: HttpClient) {}

  getList() {
    return this.http.get<Result<OrganizationViewModel[]>>(
      `${environment.api}${ORGANIZATION.endpoint}`
    );
  }

  getOrgPublic(domain: string) {
    return this.http.get<Result<FanExperienceViewModel>>(
      `${environment.api}/public/domain/${domain}`
    );
  }

  getOrg(id: string | null) {
    return this.http.get<Result<OrganizationViewModel>>(
      `${environment.api}${ORGANIZATION.endpoint}/${id}`
    );
  }

  createFanExperience(id: string | null, fanExperience: FanExperienceViewModel) {
    const form = new FormData();
    console.log(fanExperience);
    if (fanExperience.domain) form.append('domain', fanExperience.domain);
    if (fanExperience.landingMainText)
      form.append('landingMainText', fanExperience.landingMainText);
    if (fanExperience.vipDomain) form.append('vipDomain', fanExperience.vipDomain);
    if (fanExperience.clientDomain) form.append('clientDomain', fanExperience.clientDomain);
    if (fanExperience.emailTitle) form.append('emailTitle', fanExperience.emailTitle);
    if (fanExperience.emailDescription)
      form.append('emailDescription', fanExperience.emailDescription);

    if (fanExperience.about?.title) form.append('aboutTitle', fanExperience.about?.title);
    if (fanExperience.about?.text) form.append('aboutText', fanExperience.about?.text);
    if (fanExperience.faqs?.title) form.append('faqsTitle', fanExperience.faqs?.title);
    if (fanExperience.faqs?.text) form.append('faqsText', fanExperience.faqs?.text);

    if (fanExperience.privacyNotice?.title)
      form.append('privacyTitle', fanExperience.privacyNotice?.title);
    if (fanExperience.privacyNotice?.text)
      form.append('privacyText', fanExperience.privacyNotice?.text);

    if (fanExperience.termsOfUse?.title) form.append('termsTitle', fanExperience.termsOfUse?.title);
    if (fanExperience.termsOfUse?.text) form.append('termsText', fanExperience.termsOfUse?.text);

    if (fanExperience.communityStandards?.title)
      form.append('communityTitle', fanExperience.communityStandards?.title);
    if (fanExperience.communityStandards?.text)
      form.append('communityText', fanExperience.communityStandards?.text);
    if (fanExperience.refundPolicy?.title)
      form.append('refundTitle', fanExperience.refundPolicy?.title);
    if (fanExperience.refundPolicy?.text)
      form.append('refundText', fanExperience.refundPolicy?.text);
    // FILES
    if (fanExperience.logo) form.append('logo', fanExperience.logo);
    if (fanExperience.mainImage) form.append('mainImage', fanExperience.mainImage);
    if (fanExperience.logoFooter) form.append('logoFooter', fanExperience.logoFooter);

    return this.http.post<Result<void>>(`${environment.api}${ORGANIZATION.endpoint}/${id}`, form);
  }
  getOrgWithVipDomain(vipDomain: string) {
    return this.http.get<Result<string>>(
      `${environment.api}${ORGANIZATION.endpoint}/vip/domain/${vipDomain}`
    );
  }

  setUser(id: string, user: UserViewModel) {
    return this.http.post<Result<void>>(`${environment.api}${ORGANIZATION.endpoint}/${id}/vips`, {
      _id: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      notified: user.notified,
      status: user.status,
      active: user.active
    });
  }

  getListUsers(id: string) {
    return this.http.get<Result<UserViewModel[]>>(
      `${environment.api}${ORGANIZATION.endpoint}/vips/${id}`
    );
  }

  unassignUser(id: string | undefined, vipId: string) {
    return this.http.delete<Result<void>>(
      `${environment.api}${ORGANIZATION.endpoint}/${id}/vips/${vipId}`
    );
  }

  listOrganizationSubscribers(id: string | undefined) {
    return this.http.get<Result<SubscriberList[]>>(
      `${environment.api}${ORGANIZATION.endpoint}/${id}/vips/subscribers`
    );
  }
}
