<nz-layout class="app-layout">
  <nz-sider
    class="menu-sidebar"
    [ngClass]="{ 'collapsed': isCollapsed }"
    nzCollapsible
    nzWidth="256px"
    nzBreakpoint="md"
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
  >
    <div class="sidebar-logo" [ngClass]="{ 'collapsed': isCollapsed }">
      <h1>{{ organizationName }}</h1>
    </div>
    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <!-- <li nz-menu-item nzMatchRouter>
        <a [routerLink]="['/home']"> <i nz-icon nzType="home"></i> Home </a>
      </li> -->

      <li nz-menu-item nzMatchRouter *ngIf="organizationId">
        <a [routerLink]="['/users']"> <i nz-icon nzType="team"></i> Vips </a>
      </li>

      <li nz-menu-item nzMatchRouter *ngIf="organizationId">
        <a [routerLink]="['/order-profiles']"> <i  nz-icon nzType="idcard"></i> List Profiles </a>
      </li>

      <!-- <li nz-menu-item nzMatchRouter *ngIf="organizationId">
        <a [routerLink]="['/subscribers-list']"> <i nz-icon nzType="team"></i> Subscribers </a>
      </li> -->

      <li nz-menu-item nzMatchRouter *ngIf="organizationId">
        <a [routerLink]="['/reports']"> <i nz-icon nzType="file-excel"></i> Reports </a>
      </li>

      <li nz-menu-item nzMatchRouter *ngIf="!organizationId">
        <a [routerLink]="['/categories']"> <i nz-icon nzType="control"></i> Categories </a>
      </li>

      <!-- <span nz-icon nzType="control" nzTheme="outline"></span> -->

      <!-- <li nz-menu-item nzMatchRouter>
        <a [routerLink]="['/profiles']"> <i nz-icon nzType="profile"></i> Profiles </a>
      </li> -->

      <li nz-menu-item nzMatchRouter *ngIf="organizationId">
        <a [routerLink]="['/organization-management']">
          <i nz-icon nzType="shop"></i> Organization
        </a>
      </li>

      <li nz-menu-item nzMatchRouter *ngIf="organizationId">
        <a [routerLink]="['/payments']"> <i nz-icon nzType="dollar"></i> Payments </a>
      </li>

      <li nz-menu-item nzMatchRouter *ngIf="organizationId">
        <a [routerLink]="['/refunds']"> <i nz-icon nzType="dollar"></i> Refunds </a>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
          <span class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></span>
        </span>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
