import { Component, Input } from '@angular/core';

import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent {
  @Input() imageSrc!: string;

  constructor(
    private modal: NzModalRef,
  ) {
    this.imageSrc = this.modal.getConfig().nzData?.imageSrc;  // Changed from imageUrl to imageSrc
  }
}
